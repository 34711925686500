*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  
}

.heading{
  text-align: center;
  font-family:fantasy;
  text-decoration: underline;
  margin-top: 30;
  font-style: oblique;
}
body{
  display: flex;
  height: 100vh;
  background: #000000;
  background-image: url('https://source.unsplash.com/1600x900/?pattern');
  background-repeat: repeat;
  font-size: 120%;
  font-weight: 600;
  color: white;
  padding: 2em;
  border-radius: 30px;
  width: 100%;
  max-width: 420px;
  margin: 1em;
}
.container{
  background: black;
  opacity: 0.8;
  width: 60%;
  max-height: max-content;
  min-width: 450px;
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
  border-radius: 10px;
}

.container1{
  color: #000000;
  background: whitesmoke;
  opacity: 1.0;
  width: 60%;
  min-width: 450px;
  position: relative;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
  border-radius: 10px;
}

.container2{
    color: black;
    background-color: #c5e1e6;
    opacity: 5.0;
    height: 70px;
    margin-bottom: 8px;
    padding: 5px 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 10px;
    display: flex;
    border-radius: 5px;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #939697;
    cursor: pointer;
}



#newtask{
  position: relative;
  padding: 30px 20px;
}
#newtask input{
  width: 75%;
  height: 45px;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  border: 2px solid #d1d3d42b;
  padding: 12px;
  color: #111111;
  font-weight: 500;
  position: relative;
  border-radius: 5px;
}
#newtask input:focus{
  outline: none;
  border-color: #0d75ec;
}

#newtask button{
  position: relative;
  float: right;
  width: 20%;
  height: 45px;
  border-radius: 5px;
  font-family: 'Poppins',sans-serif;
  font-weight: 500;
  font-size: 16px;
  background-color: #0d75ec;
  border: none;
  color: white;
  cursor: pointer;
  outline: none;
}
#tasks{
  background-color: #ffffff;
  padding: 30px 20px;
  margin-top: 70px;
  border-radius: 10px;
  width: 95%;
  padding-left: 20px;
  margin-left: 10px;
  margin-right: 20px;
  margin-bottom: 30px;
  position:absolute;
}

.tasks button{
  background-color: #0a2ea4;
  color: #ffffff;
  height: 100%;
  width: 40px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  outline: none;
}

.todo-text input {
  padding: 4px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-style: italic;
}
.todo-text {
  font-size: 24px;
  font-family: fantasy;
  line-height: 24px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
.todo-actions {
  display: flex;
  justify-content: space-between;
}
.todo-text input {
  padding: 4px;
}

input[type='text'] { 
  font-size: 20px; 
  font-family:'Times New Roman'; 
  font-style: italic;
  font-weight: 600;
}

.todo-text {
  display: flex;
  line-height: 24px;
  margin-bottom: 16px;
  align-items: center;
}

button {
  float: center;
  position: relative;
  font-size: 15px;
  background-color: rgb(217, 60, 35);
  color: #fff;
  border-radius: 8px;
  margin-top: 1px;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
}
button:hover {
  background-color: rgb(173, 47, 28);
}
button:active {
  background-color: rgb(130,35,21);
}
button:focus {
  outline: 0;
}


